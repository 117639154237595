<template>
  <div class="list">
    <div class="healt-advice item" @click="getPdf">
      <img src="../assets/healthAdvice-icon.png" alt="">
      <p>健康告知书</p>
    </div>
    <div class="rate-table item" @click="fileList('rateTable')">
      <img src="../assets/rateTable-icon.png" alt="">
      <p>费率表</p>
    </div>
    <div class="product-terms item" @click="fileList('productTerms')">
      <img src="../assets/productTerms-icon.png" alt="">
      <p>产品条款</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
    };
  },
  created() {
    
  },
  methods: {
    //查看健康告知书
    getPdf(){
      this.$router.push({
        path:"/pdf"
      })
    },
    //跳转pdf列表
    fileList(type){
      this.$router.push({
        path:'/fileList',
        query:{
          id:this.$route.query.id,
          type:type
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.list{
  display: flex;
  align-items: center;
  padding: 28px 40px;
}
.item{
  display: flex;
  flex-direction: column;
  align-items: center;
  p{
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    line-height: 17px;
  }
}
.healt-advice{
  
  img{
    width: 23px;
  }
  p{
    margin-top: 9px;
  }
}
.rate-table{
  margin: 0 36px 0 31px;
  img{
    width: 30px;
  }
  p{
    margin-top: 8px;
  }
}
.product-terms{
  img{
    width: 23px;
  }
  p{
    margin-top: 8px;
  }
}
</style>